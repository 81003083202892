.active-campaign-total-number {
  color: #0433BF;
  font-size: 15px;
  text-align: center
}

.active-campaign-total-text {
  font-size: 10px;
  text-align: center
}

thead th {
  position: sticky;
  top: 0;
  z-index: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.active-campaign-th {
  background: #F0F0F0;
  background-color: #F0F0F0 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.wrapper {
  position: relative;
  overflow: auto;
  white-space: nowrap;
}

.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: #F0F0F0 !important;
}

.first-col-active-campaign {
  overflow-wrap: break-word;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
  z-index: 98;
  background-color: #fff !important;
}

.second-col-active-campaign {
  overflow-wrap: break-word;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  left: 100px;
  z-index: 98;
  background-color: #fff !important;
}

.third-col-active-campaign {
  overflow-wrap: break-word;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 450px;
  z-index: 98;
  background-color: #fff !important;
}

.first-col-header-active-campaign {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #f2f2f2 !important;
}

.second-col-header-active-campaign {
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  left: 100px;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #f2f2f2 !important ;
}

.third-col-header-active-campaign {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 450px;
  z-index: 99;
  position: sticky;
  top: 0;
  background-color: #f2f2f2 !important;
}